<template>
  <div id="admin-login">
    <div class="admin-login-wrap">
      <div class="admin-login-title">登录 MrLin's</div>
      <div class="admin-login-input-group" v-loading="loginButtonLoading">
        <el-input
          placeholder="账号"
          prefix-icon="el-icon-myblog-account"
          v-model="adminLogin.username"
          autofocus>
        </el-input>
        <el-input
          placeholder="密码"
          prefix-icon="el-icon-myblog-password"
          type="password"
          @keyup.enter.native='login'
          v-model="adminLogin.password">
        </el-input>
        <el-button
          type="primary"
          @click="login"
          plain>
          登录
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'amdin-login',
  data () {
    return {
      adminLogin: {
        username: '',
        password: ''
      },
      loginButtonLoading: false
    }
  },
  methods: {
    ...mapActions([
      'userLogin'
    ]),
    ...mapMutations([
      'setToken'
    ]),
    login () {
      if (this.adminLogin.username && this.adminLogin.password && this.loginButtonLoading === false) {
        this.loginButtonLoading = true
        this.userLogin(this.adminLogin).then((response) => {
          this.loginButtonLoading = false
          this.setToken(response.token)
          this.$router.push({ name: 'AdminIndex' })
        }).catch((error) => {
          if (error.status === 401) {
            this.loginButtonLoading = false
            this.$message.error('账号或密码错误')
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-login
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  .admin-login-wrap
    position: relative
    max-width: 460px
    top: 30%
    border-radius: 10px
    margin: 20px auto
    .admin-login-title
      font-weight: 600
      font-size: 18pt
    .admin-login-input-group
      margin: 20px
      div
        margin: 5px 0
      button
        margin: 5px 0
        width: 100%
</style>
